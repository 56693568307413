// 订单操作
export default {
	// 充值
	mtobPage:{      //页面获取
        method:"post",
		url:"/admin/recordsRecharge/listPage"
    },
    // 余额
    balancePage:{
        method:"post",
        url:"/admin/recordsBalance/listPage"
    },

    //佣金申请
    withdrawApplyPage:{     //分页获取佣金申请
        method:"post",
        url:"/admin/withdrawApply/listPage"
    },
    withdrawApplyDel:{     //删除佣金申请
        method:"delete",
        url:"/admin/withdrawApply/:id"
    },
    withdrawApplyUp:{     //删除佣金申请
        method:"put",
        url:"/admin/withdrawApply/:id"
    },

    //资产记录
    MoneyPage:{          //获取所有记录资产明细
        method:"post",
        url:"/admin/recordsMoney/listPage"
    },

    //获得所有日记账
    DayPage:{
        method:"get",
        url:"/admin/recordsDay"
    }
    
}