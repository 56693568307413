<template>
  <router-view/>
</template>

<style>
*{padding:0px; margin:0px;}
html,body,#app,.el-container{
    padding: 0px;
    margin: 0px;
    height: 100%;
    background-color: #f2f2f2;
}
</style>
