//会员模块

export default {
	/**
	 * 品牌
	**/
	getGoodsBrandById:{
		method:"get",
		url:"/admin/goodsBrand/:id"
	},
	putGoodsBrandById:{
		method:"put",
		url:"/admin/goodsBrand/:id"
	},
	deleteGoodsBrandById:{
		method:"delete",
		url:"/admin/goodsBrand/:id"
	},
	getGoodsBrandAll:{
		method:"get",
		url:"/admin/goodsBrand"
	},
	addGoodsBrand:{
		method:"post",
		url:"/admin/goodsBrand"
	},
	/**
	 * 分类
	**/
	getGoodsClassifyById:{
		method:"get",
		url:"/admin/goodsClassify/:id"
	},
	putGoodsClassifyById:{
		method:"put",
		url:"/admin/goodsClassify/:id"
	},
	deleteGoodsClassifyById:{
		method:"delete",
		url:"/admin/goodsClassify/:id"
	},
	getGoodsClassifyAll:{
		method:"get",
		url:"/admin/goodsClassify"
	},
	addGoodsClassify:{
		method:"post",
		url:"/admin/goodsClassify"
	},
	/**
	 * 信息表
	**/
	getGoodsInfoById:{
		method:"get",
		url:"/admin/goodsInfo/:id"
	},
	putGoodsInfoById:{
		method:"put",
		url:"/admin/goodsInfo/:id"
	},
	deleteGoodsInfoById:{
		method:"delete",
		url:"/admin/goodsInfo/:id"
	},
	getGoodsInfoAll:{
		method:"get",
		url:"/admin/goodsInfo"
	},
	addGoodsInfo:{
		method:"post",
		url:"/admin/goodsInfo"
	},
	GoodsInfoPage:{
		method:"post",
		url:"/admin/goodsInfo/listPage"
	},
	/**
	 * 规格
	**/
	getGoodsSpecificationsById:{
		method:"get",
		url:"/admin/goodsSpecifications/:id"
	},
	putGoodsSpecificationsById:{
		method:"put",
		url:"/admin/goodsSpecifications/:id"
	},
	deleteGoodsSpecificationsById:{
		method:"delete",
		url:"/admin/goodsSpecifications/:id"
	},
	getGoodsSpecificationsAll:{
		method:"get",
		url:"/admin/goodsSpecifications"
	},
	addGoodsSpecifications:{
		method:"post",
		url:"/admin/goodsSpecifications"
	},
	getGoodsSpecificationsByGoodsId:{
		method:"get",
		url:"/admin/goodsSpecifications/listByGoodsId/:goodsid"
	},
	/**
	 * 会员特殊分佣
	**/
	getGoodsVipById:{
		method:"get",
		url:"/admin/goodsVip/:id"
	},
	putGoodsVipById:{
		method:"put",
		url:"/admin/goodsVip/:id"
	},
	deleteGoodsVipById:{
		method:"delete",
		url:"/admin/goodsVip/:id"
	},
	getGoodsVipAll:{
		method:"get",
		url:"/admin/goodsVip"
	},
	addGoodsVip:{
		method:"post",
		url:"/admin/goodsVip"
	},
	/**
	 * 团队特殊分佣
	**/
	getGoodsTeamById:{
		method:"get",
		url:"/admin/goodsTeam/:id"
	},
	putGoodsTeamById:{
		method:"put",
		url:"/admin/goodsTeam/:id"
	},
	deleteGoodsTeamById:{
		method:"delete",
		url:"/admin/goodsTeam/:id"
	},
	getGoodsTeamAll:{
		method:"get",
		url:"/admin/goodsTeam"
	},
	addGoodsTeam:{
		method:"post",
		url:"/admin/goodsTeam"
	},
}