//系统类

export default {
	/**
	 * 作者
	 */
	getArticleAuthorById:{
		method:"get",
		url:"/admin/articleAuthor/:id"
	},
	putArticleAuthorById:{
		method:"put",
		url:"/admin/articleAuthor/:id"
	},
	deleteArticleAuthorById:{
		method:"delete",
		url:"/admin/articleAuthor/:id"
	},
	getArticleAuthorAll:{
		method:"get",
		url:"/admin/articleAuthor"
	},
	addArticleAuthor:{
		method:"post",
		url:"/admin/articleAuthor"
	},
	/**
	 * 标签
	 */
	getArticleTagById:{
		method:"get",
		url:"/admin/articleTag/:id"
	},
	putArticleTagById:{
		method:"put",
		url:"/admin/articleTag/:id"
	},
	deleteArticleTagById:{
		method:"delete",
		url:"/admin/articleTag/:id"
	},
	getArticleTagAll:{
		method:"get",
		url:"/admin/articleTag"
	},
	addArticleTag:{
		method:"post",
		url:"/admin/articleTag"
	},
	/**
	 * 新闻
	 */
	getArticleInfoById:{
		method:"get",
		url:"/admin/articleInfo/:id"
	},
	putArticleInfoById:{
		method:"put",
		url:"/admin/articleInfo/:id"
	},
	deleteArticleInfoById:{
		method:"delete",
		url:"/admin/articleInfo/:id"
	},
	getArticleInfoAll:{
		method:"get",
		url:"/admin/articleInfo"
	},
	addArticleInfo:{
		method:"post",
		url:"/admin/articleInfo"
	},
}