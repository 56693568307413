import { createStore } from 'vuex'

export default createStore({
  state: {
    //公共的
    layout:{
      active:{
        mobileShow:false,
        mobileShowModel:''
      }
    }
  },
  mutations: {
    MobileShow:(state,acitve)=>{
      state.layout.active.mobileShow=active.open;
      state.layout.active.mobileShowModel=active.model;
    }
  },
  actions: {
  },
  modules: {
  }
})
