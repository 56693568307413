window.$ =  (tag)=>{
    return document.querySelector(tag);
}

window.rand = (n,m) => {
	var aNumber = (n+1- m) * Math.random() + m;
	var result = Math.floor(aNumber);
	return result;
}

window.ref2obj = (tableData)=>{
    let newArray = [];
    for(let i in tableData){
      let obj = {}
      for(let [k,v] of Object.entries(tableData[i])){
        obj[k]=v;
      }
      newArray.push(obj);
    }
    return newArray;
}

window.getURL = {
  last:()=>{
    let str =window.location.pathname;
    return str.split("/").pop();
  }
}

window.order = (property) =>{
    return function(a,b){
    var value1 = a[property];
    var value2 = b[property];
    return value1 - value2;
    }
}

window.o2s = (obj) => {
		if(!obj){
			return;
		}
		let str = JSON.stringify(obj);
		return str;
}

window.s2o = (str)=>{
  if(!str){
    return;
  }
  let obj = JSON.parse(str);
  return obj;
}