//运营模块

export default {
	/**
	 * 广告板块
	**/
	getCommonAdsById:{
		method:"get",
		url:"/admin/commonAds/:id"
	},
	putCommonAdsById:{
		method:"put",
		url:"/admin/commonAds/:id"
	},
	deleteCommonAdsById:{
		method:"delete",
		url:"/admin/commonAds/:id"
	},
	getCommonAdsAll:{
		method:"get",
		url:"/admin/commonAds"
	},
	addCommonAds:{
		method:"post",
		url:"/admin/commonAds"
	},
	/**
	 * 客服信息
	 */
	saveCallCenterWork:{
		method:"put",
		url:"/admin/callCenterWork/:id"
	},
	getCallCenterWork:{
		method:"post",
		url:"/admin/callCenterWork/listPage"
	},
	/**
	 * 评价管理
	 */
	evaluateList:{
		method:"post",
		url:"/admin/goodsEvaluate/listPage"
	},
	evaluateUp:{
		method:"put",
		url:"/admin/goodsEvaluate/:id"
	}
}