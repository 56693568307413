//api模块注册

//导入模块
import vip from "@/config/api/modular/vip"
import goods from "@/config/api/modular/goods"
import operate from "@/config/api/modular/operate"
import sys from "@/config/api/modular/sys"
import news from "@/config/api/modular/news"
import config from "@/config/api/modular/config"
import order from "@/config/api/modular/order"
import records from "@/config/api/modular/records"

//暴露模块
export default {
	vip,goods,operate,sys,news,config,order,records
}