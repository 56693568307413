import { createRouter, createWebHistory } from 'vue-router'


const routes = [
  {
    path: '/',
    component: () => import('@/views/layout.vue'),
    redirect:"/index",
    children:[
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index/index.vue'),
      },
      //商城
      {
        path: '/goods/index',
        name: 'goodsIndex',
        component: () => import('@/views/goods/index.vue'),
      },
      {
        path: '/goods/info',
        name: 'goodsInfo',
        component: () => import('@/views/goods/info.vue'),
      },
      {
        path: '/goods/brand',
        name: 'goodsBrand',
        component: () => import('@/views/goods/brand.vue'),
      },
      {
        path: '/goods/classify',
        name: 'goodsClassify',
        component: () => import('@/views/goods/classify.vue'),
      },
      //会员
      {
        path: '/vip/index',
        name: 'vipIndex',
        component: () => import('@/views/vip/index.vue'),
      },
      {
        path: '/vip/info',
        name: 'vipInfo',
        component: () => import('@/views/vip/vipInfo.vue'),
      },
      {
        path: '/vip/level',
        name: 'vipLevel',
        component: () => import('@/views/vip/vipLevel.vue'),
      },
      {
        path: '/team/info',
        name: 'teamInfo',
        component: () => import('@/views/vip/teamInfo.vue'),
      },
      /** 运营 */
      //运营
      {
        path: '/operate/index',
        name: 'operateIndex',
        component: () => import('@/views/operate/index.vue'),
      },
      //活动
      {
        path: '/operate/activity',
        name: 'operateActivity',
        component: () => import('@/views/operate/activity.vue'),
      },
      //评价管理
      {
        path: '/operate/evaluate',
        name: 'operateEvaluate',
        component: () => import('@/views/operate/evaluate.vue'),
      },
      //评价管理
      {
        path: '/operate/evaluate/:type/:id',
        name: 'operateEvaluateOnly',
        component: () => import('@/views/operate/evaluateOnly.vue'),
      },
      //预警信息
      {
        path: '/operate/event',
        name: 'operateEvent',
        component: () => import('@/views/operate/event.vue'),
      },
      //系统工单
      {
        path: '/operate/work',
        name: 'operateWork',
        component: () => import('@/views/operate/work.vue'),
      },
      //订单管理
      {
        path: '/operate/order',
        name: 'operateOrder',
        component: () => import('@/views/operate/order.vue'),
      },
      //售后管理
      {
        path: '/operate/aftersales',
        name: 'operateAftersales',
        component: () => import('@/views/operate/aftersales.vue'),
      },
      //广告管理
      {
        path: '/operate/ad',
        name: 'operateAd',
        component: () => import('@/views/operate/ad.vue'),
      },
      //新闻动态
      {
        path: '/operate/news',
        name: 'operateNews',
        component: () => import('@/views/operate/news.vue'),
      },
      /**
       * 统计
       */
      //统计
      {
        path: '/records/index',
        name: 'recordsIndex',
        component: () => import('@/views/records/index.vue'),
      },
      {
        path: '/records/day',
        name: 'recordsDay',
        component: () => import('@/views/records/day.vue'),
      },
      {
        path: '/records/money',
        name: 'recordsMoney',
        component: () => import('@/views/records/money.vue'),
      },
      {
        path: '/records/balance',
        name: 'recordsBalance',
        component: () => import('@/views/records/balance.vue'),
      },
      {
        path: '/records/coin',
        name: 'recordsCoin',
        component: () => import('@/views/records/coin.vue'),
      },
      {
        path: '/records/kickback',
        name: 'recordsKickback',
        component: () => import('@/views/records/kickback.vue'),
      },
      {
        path: '/records/moneyToBalance',
        name: 'recordsMoneyToBalance',
        component: () => import('@/views/records/recordsMoneyToBalance.vue'),
      },
      {
        path: '/records/kickback/apply',
        name: 'recordsKickbackApply',
        component: () => import('@/views/records/kickbackApply.vue'),
      },
      
      {
        path: '/records/stock',
        name: 'recordsStock',
        component: () => import('@/views/records/stock.vue'),
      },
      /**
       * 策略
       */
      //策略
      {
        path: '/config/index',
        name: 'configIndex',
        component: () => import('@/views/config/index.vue'),
      },
      {
        path: '/config/finance',
        name: 'configFinance',
        component: () => import('@/views/config/finance.vue'),
      },
      {
        path: '/config/goods',
        name: 'configGoods',
        component: () => import('@/views/config/goods.vue'),
      },
      {
        path: '/config/vip',
        name: 'configVip',
        component: () => import('@/views/config/vip.vue'),
      },
      {
        path: '/config/team',
        name: 'configTeam',
        component: () => import('@/views/config/team.vue'),
      },
      {
        path: '/config/warning',
        name: 'configWarning',
        component: () => import('@/views/config/warning.vue'),
      },
      /**
       * 系统
       */
      //系统
      {
        path: '/sys/index',
        name: 'sysIndex',
        component: () => import('@/views/sys/index.vue'),
      },
	  {
	    path: '/sys/article',
	    name: 'sysArticle',
	    component: () => import('@/views/sys/article.vue'),
	  },
    ]
  },
  {
    path:"/login",
    name:'login',
    component:()=> import("@/views/login.vue")
  },
  {
    path:"/guide",
    name:'guide',
    component:()=> import("@/views/guide.vue")
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next)=>{
  if(to.name!="login" && !localStorage.getItem('zxg_token')){
    router.push({name:"login"})
  }else{
    next()
  }
})

export default router
