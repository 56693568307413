import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/unit/joyn.js'
//element
import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn';
import 'element-plus/dist/index.css'
import '@/common/icon/iconfont.css'
import httpServe from "@/common/js/api"

window.$api = httpServe;

createApp(App).use(store).use(router).use(ElementPlus,{locale}).mount('#app')
