export default {
	timeout:5000,
	environment:"prod",
	baseUrl:{
		dev:"http://127.0.0.1:8001",
		prod:"/api"
	},
	upUrl:{
		dev:"http://127.0.0.1:8001/common/upFile",
		prod:"/api/upFile"
	}
}