import Service from "@/unit/service.js"
import apiM from "@/config/api/modular"



const api = async (modular,apiname,data={}) =>{
	let apiObj = apiM[modular][apiname];
	let newUrl = apiObj.url;
	let newData={};
	//restful网址自动转换
	for(let [key,value] of Object.entries(data)){
		if(newUrl.includes("/:"+key)){
			newUrl = newUrl.replace("/:"+key,"/"+value);
		}else{
			newData[key]=value;
		}
	}
	//Get,Delete等不含身体的以？陈列多余data
	if(["get","delete"].includes(apiObj.method) && Object.keys(newData).length>0){
		newUrl+="?";
		for(let [key,value] of Object.entries(newData)){
			newUrl += key+"="+value+"&";
		}
		newUrl = newUrl.substr(0,newUrl.length-1);
	}
	
	//组装发送数据
	let sendData = {
		method: apiObj.method,
		url:newUrl,
		data:newData
	}
	
	//发送结果
	return await Service(sendData);
}

export default api;