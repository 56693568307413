// 配置
export default {
	// 策略
	saveOrUpdatePolicy:{
		method:"post",
		url:"/admin/configPolicy/saveOrUpdate"
	},
	getByTypePolicy:{
		method:"get",
		url:"/admin/configPolicy/getByType/:type"
	},
}