//系统类

export default {
	/**
	 * 协议文章
	 */
	getCommonArticleById:{
		method:"get",
		url:"/admin/commonArticle/:id"
	},
	putCommonArticleById:{
		method:"put",
		url:"/admin/commonArticle/:id"
	},
	deleteCommonArticleById:{
		method:"delete",
		url:"/admin/commonArticle/:id"
	},
	getCommonArticleAll:{
		method:"get",
		url:"/admin/commonArticle"
	},
	addCommonArticle:{
		method:"post",
		url:"/admin/commonArticle"
	},
}