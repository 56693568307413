//会员模块

export default {
	/**
	 * 团队
	 */
	getTeamById:{
		method:"get",
		url:"/admin/vipTeam/:id"
	},
	putTeamById:{
		method:"put",
		url:"/admin/vipTeam/:id"
	},
	deleteTeamById:{
		method:"delete",
		url:"/admin/vipTeam/:id"
	},
	getTeamAll:{
		method:"get",
		url:"/admin/vipTeam"
	},
	addTeam:{
		method:"post",
		url:"/admin/vipTeam"
	},
	
	/**
	 * 等级
	 */
	getLevelById:{
		method:"get",
		url:"/admin/vipLevel/:id"
	},
	putLevelById:{
		method:"put",
		url:"/admin/vipLevel/:id"
	},
	deleteLevelById:{
		method:"delete",
		url:"/admin/vipLevel/:id"
	},
	getLevelAll:{
		method:"get",
		url:"/admin/vipLevel"
	},
	addLevel:{
		method:"post",
		url:"/admin/vipLevel"
	},
	
	/**
	 * 信息表
	*/
	getVipInfoById:{
		method:"get",
		url:"/admin/vipInfo/:id"
	},
	putVipInfoById:{
		method:"put",
		url:"/admin/vipInfo/:id"
	},
	deleteVipInfoById:{
		method:"delete",
		url:"/admin/vipInfo/:id"
	},
	getVipInfoAll:{
		method:"get",
		url:"/admin/vipInfo"
	},
	VipInfoPage:{
		method:"post",
		url:"/admin/vipInfo/listPage"
	},
	addVipInfo:{
		method:"post",
		url:"/admin/vipInfo"
	},
	regulateInfo:{
		method:"post",
		url:"/admin/vipInfo/regulate"
	},
	/**
	 * 会员地址表
	*/
	getAddressById:{
		method:"get",
		url:"/admin/vipAddress/:id"
	},
	getAddressByVipMoible:{
		method:"get",
		url:"/admin/vipAddress/getByVipMobile/:vipMobile"
	},
	putAddressById:{
		method:"put",
		url:"/admin/vipAddress/:id"
	},
	deleteAddressById:{
		method:"delete",
		url:"/admin/vipAddress/:id"
	},
	getAddressAll:{
		method:"get",
		url:"/admin/vipAddress"
	},
	addAddress:{
		method:"post",
		url:"/admin/vipAddress"
	},
	/**
	 * 会员银行表
	*/
	getBankById:{
		method:"get",
		url:"/admin/vipBank/:id"
	},
	getBankByVipMoible:{
		method:"get",
		url:"/admin/vipBank/getByVipMobile/:vipMobile"
	},
	putBankById:{
		method:"put",
		url:"/admin/vipBank/:id"
	},
	deleteBankById:{
		method:"delete",
		url:"/admin/vipBank/:id"
	},
	getBankAll:{
		method:"get",
		url:"/admin/vipBank"
	},
	addBank:{
		method:"post",
		url:"/admin/vipBank"
	},
	//登录
	login:{
		method:"post",
		url:"/loginPassword"
	},
	
}