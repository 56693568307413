import axios from "axios"
import ApiC from "@/config/api/common"
import { ElNotification,ElMessageBox } from 'element-plus'


const Service = axios.create({
	timeout:ApiC.timeout,
	baseURL:ApiC.baseUrl[ApiC.environment],
	headers:{
		"content-type":"application/json;charset=utf-8",
		"Authorization":""
	}
})

//请求拦截
Service.interceptors.request.use(res=>{
	let tokenName="zxg_token";
	//添加token
	if(localStorage.getItem(tokenName)){res.headers["Authorization"]=`Bearer ${localStorage.getItem(tokenName)}`;}
	//加锁
	return res;
	
},err=>{
	return err;
})

//响应拦截
Service.interceptors.response.use(res=>{
	if(res.data.code==403){
		ElMessageBox.alert('您的登录信息已超时，请重新登录', '超时提醒', {
			confirmButtonText: '重新登录',
			callback: action => {
				window.location.href="/login"
			}
		});
		return;
	}
	//200
	return res.data;
},err=>{
	switch(err.request.status){
		case 0:
			ElNotification.error({
			    title: '网络状态不良，请求超时!',
			    message: `${err.config.baseURL}${err.config.url}`,
			    showClose: true,
			})
		break;
		case 404:
			ElNotification.error({
			    title: '找不到网络地址!',
			    message: `${err.config.baseURL}${err.config.url}`,
			    showClose: true,
			})
		break;
	}
	
})

export default Service;